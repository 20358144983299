<template>
  <v-theme-provider dark>
    <base-section id="reference-finder" class="primary" space="36">
      <v-row align="center" class="ma-0" justify="center">
        <base-title space="0" title="SELECTIONNEZ VOTRE MODELE" />

        <v-responsive class="px-4 mx-6" max-width="600" width="100%">
          <v-select
            :items="items"
            label="Référence produit"
            v-model="selected"
            @change="onChange"
          />
        </v-responsive>
      </v-row>

      <v-row align="center" class="ma-0" justify="center">
        <p class="white--text">
          Ou trouver le modéle de mon radiateur?
        </p>
      </v-row>
      <v-row align="center" class="ma-0" justify="center">
        <v-btn
            class="mx-2"
            fab
            dark
            large
            color="primary"
            @click="dialog = true"
        >
          <font-awesome-icon icon="question" />
        </v-btn>
      </v-row>

      <locate-ref-dialog
          :show="dialog"
          @close="dialog = false"
      />

      <!--      <v-row align="center" class="ma-0" justify="center">-->
      <!--        <v-responsive class="px-4 mx-6" max-width="600" width="100%">-->
      <!--          <b style="color:white;font-size: 2em;">-->
      <!--            La référence produit se trouve sur le côté de l'appareil.-->
      <!--          </b>-->
      <!--        </v-responsive>-->
      <!--      </v-row>-->
    </base-section>
  </v-theme-provider>
</template>

<script>
export default {
  name: "ReferenceFinder",

  components: {
    LocateRefDialog: () => import("@/components/LocateRefDialog"),
  },
  mounted() {
    this.$store.dispatch("fetchReferencesName");
  },
  computed: {
    items() {
      return this.$store.getters.referencesName;
    },
    supportFiles() {
      return this.$store.getters.supportFiles;
    }
  },

  data: () => ({
    selected: null,
    dialog: false
  }),
  methods: {
    onChange(element) {
      this.$emit("selected", element);
    }
  }
};
</script>
